<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getInvalidByShop"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="voidBillOrderTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
              {{ $t('report.txtViodOrder') }}
            </h2>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="voidBillOrderExportButton" block color="info" v-on:click="exportInvalidByShop()">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <div style="overflow-x:auto;">
              <DataTable
                id="voidBillOrderDatatableS"
              style="white-space: nowrap;"
                :items="updateitems()"
                :fields="fields"
                :loading="loading"
                hover
                border
                clickableRows
                @row-clicked="rowClicked"
                
              >
              </DataTable>
            </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getInvalidByShop">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('orderDevice', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('orderDevice', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'date',
          label: this.$i18n.t('date'),
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'vatAmount',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netTotal',
          label: this.$i18n.t('netTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let netTotal = ''
      let discountAmount = ''
      let vatAmount = ''
      let netAmount = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].netTotal === undefined) {
          netTotal = '-'
        } else {
          netTotal = util.convertCurrency(data[i].netTotal)
        }

        if (data[i].discountAmount === undefined) {
          discountAmount = '-'
        } else {
          discountAmount = util.convertCurrency(data[i].discountAmount)
        }

        if (data[i].vatAmount === undefined) {
          vatAmount = '-'
        } else {
          vatAmount = util.convertCurrency(data[i].vatAmount)
        }

        if (data[i].netAmount === undefined) {
          netAmount = '-'
        } else {
          netAmount = util.convertCurrency(data[i].netAmount)
        }

        detail.push({
          date: moment(String(data[i].date)).format('DD/MM/YYYY'),
          dateString: data[i].date,
          netTotal: netTotal,
          discountAmount: discountAmount,
          vatAmount: vatAmount,
          netAmount: netAmount,
        })
      }

      return detail
    },
  },
  created() {
    this.getInvalidByShop()
  },
  methods: {
    ...util,
    updateitems() {
      let data = this.data
      let detail = []
      let netTotal = ''
      let discountAmount = ''
      let vatAmount = ''
      let netAmount = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].netTotal === undefined) {
          netTotal = '-'
        } else {
          netTotal = util.convertCurrency(data[i].netTotal)
        }

        if (data[i].discountAmount === undefined) {
          discountAmount = '-'
        } else {
          discountAmount = util.convertCurrency(data[i].discountAmount)
        }

        if (data[i].vatAmount === undefined) {
          vatAmount = '-'
        } else {
          vatAmount = util.convertCurrency(data[i].vatAmount)
        }

        if (data[i].netAmount === undefined) {
          netAmount = '-'
        } else {
          netAmount = util.convertCurrency(data[i].netAmount)
        }

        detail.push({
          date: moment(String(data[i].date)).format('DD MMMM YYYY'),
          dateString: data[i].date,
          netTotal: netTotal,
          discountAmount: discountAmount,
          vatAmount: vatAmount,
          netAmount: netAmount,
        })
      }

      return detail
    },
    rowClicked(item) {
      this.$router.push(
        '/report/voidBillOrder/' + this.shopObjectId + '/' + item.dateString
      )
    },
    getInvalidByShop(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/api/v1.0/' + uid + '/order/getinvalidbyshop/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportInvalidByShop(page = 1) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      report({
        url: '/api/v1.0/' + uid + '/order/getinvalidbyshop/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'รายงานยกเลิกการขายสินค้าตามเครื่องOrder.xlsx'
          ) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>



